import React from 'react';
import {TermsAndConditions} from "../../components/Terms";
import {PageProps} from "gatsby";
import {EmbedLayout} from "../../containers/EmbedLayout";

export type TermsProps = {} & PageProps;
export default function Terms({...props}: TermsProps) {
    return <EmbedLayout {...props} meta={{title: 'Terms & Conditions'}}>
        <div className={'p-m-3'}>
            <TermsAndConditions/>
        </div>
    </EmbedLayout>;
}
